import Header from "../Header/Header";
import Footer from "../Footer/Footer.js";
import "./Products.css";
import { Link } from "react-router-dom";
const Products = () => {
  return (
    <div>
      <div>
        <Header />
      </div>

      <div className="product_topic">
        <p className="product_topic_name"> OUR PRODUCTS</p>
      </div>

      <div className="product_rack">
        <div className="product_item">
          <Link to="/Product_list_without_image/oil" className="link">
            <img className="product_image" src="./images/OIL.jpg"></img>
            <p className="product_name">AYURVEDIC OIL</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/churna" className="link">
            <img
              className="product_image"
              src="./images/churna.jpg"
            ></img>
            <p className="product_name">AYURVEDIC CHURNA</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/guli" className="link">
            <img className="product_image" src="./images/guli.jpg"></img>
            <p className="product_name">AYURVEDIC GULI</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/rasayana" className="link">
            <img className="product_image" src="./images/rasayana.png"></img>
            <p className="product_name">AYURVEDIC RASAYANA</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/kalka" className="link">
            <img className="product_image" src="./images/kalka.jpg"></img>
            <p className="product_name">AYURVEDIC KALKA</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/arishta" className="link">
            <img className="product_image" src="./images/ayurvedic_medicines_large.jpg"></img>
            <p className="product_name">AYURVEDIC ARISHTA</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/ghrita" className="link">
            <img className="product_image" src="./images/ghritha.png"></img>
            <p className="product_name">AYURVEDIC GHRTHA</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/pattu" className="link">
            <img className="product_image" src="./images/pattu.jpg"></img>
            <p className="product_name">AYURVEDIC PATTU</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/unanisiddi" className="link">
            <img className="product_image" src="./images/unani.jpg"></img>
            <p className="product_name">UNANI AND SIDDI</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/rasa" className="link">
            <img className="product_image" src="./images/welcome.jpg"></img>
            <p className="product_name">RASA AUSHADA</p>
          </Link>
        </div>
       
        <div className="product_item">
          <Link to="/Product_list_without_image/rawoil" className="link">
            <img className="product_image" src="./images/raw_oil.jpg"></img>
            <p className="product_name">AYURVEDIC RAW OIL</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/tablets" className="link">
            <img className="product_image" src="./images/guli.jpg"></img>
            <p className="product_name">AYURVEDIC TABLETS</p>
          </Link>
        </div>
        <div className="product_item">
          <Link to="/Product_list_without_image/other" className="link">
            <img className="product_image" src="./images/OIL.jpg"></img>
            <p className="product_name">OTHER PRODUCTS</p>
          </Link>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Products;
