import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Home from "./Home/Home";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AboutUs from "./AboutUs/AboutUs";
import Products from "./Products/Products";
import ContactUs from "./ContactUs/ContactUs";
import Careers from "./Careers/Careers";
import Product_list from "./Product_list/Product_list";
import Footer from "./Footer/Footer";
import Gallery from "./Gallery/Gallery";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ProductCategory from "./ProductCategory/ProductCategory";
import ProductCategoryWithoutImages from "./ProductCategory/ProductCategoryWithoutImages";

ReactDOM.render(
  <React.StrictMode>
    <div>
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
    </div>

    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/product" element={<Products />} />
          <Route exact path="/ContactUs" element={<ContactUs />} />
          <Route exact path="/Careers" element={<Careers />} />
          <Route exact path="/Gallery" element={<Gallery />} />
          <Route exact path="/Product_list" element={<Product_list />}>
            <Route exact path=":category" element={<ProductCategory />} />
          </Route>
          <Route
            exact
            path="/Product_list_without_image"
            element={<Product_list />}
          >
            <Route
              exact
              path=":category"
              element={<ProductCategoryWithoutImages />}
            />
          </Route>
        </Routes>
      </div>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
