import Header from "../Header/Header";
import Footer from "../Footer/Footer.js";
import "./Product_list.css";
import { Outlet, useParams } from "react-router";
import { Link } from "react-router-dom";

import categories from "../db/ProductCategoryDB.json";
import category from "../db/ProductListDB.js"

const Product_list = () => {

  const param = useParams();
  const { category: currentCategory } = param;
console.log(currentCategory);
  return (
    <div>
      <div>
        <Header />
      </div>

      <div className="product_list_body">
        <div className="pl_left_side">
          
          {categories.map((category) => (
            <div className={`link_item_names ${currentCategory} ${category.id===currentCategory? 'active_category':''}`}>
              <Link className="link_item" to={category.id}>{category.label}</Link>
            </div>
          ))}
        </div>
        <div className="pl_right_side">
          
          <Outlet />
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Product_list;
