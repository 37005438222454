import Header from "../Header/Header";
import Footer from "../Footer/Footer.js";
import "./ContactUs.css";
import { useState, useEffect } from "react";

function ContactUs() {
  const initialValues = { fname: "", email: "", phone: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(formValues);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);
  const validate = (values) => {
    const errors = {};
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.fname) {
      errors.fname = "Name is Required";
    }
    if (!values.email) {
      errors.email = "Email is Required";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not the valid email";
    }
    if (!values.phone) {
      errors.phone = "Phone No is Required";
    }
    if (!values.message) {
      errors.message = "Message is Required";
    }
    return errors;
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="body_cont">
        <div className="contact_body">
          <div className="contact_contents">
            <div class="container">
              <div class="card">
                <div class="face face1">
                  <div className="top_box">
                    <div class="content">
                      <img className="contact_icon" src="./images/addr_2.png" />
                      <h3>ADDRESS</h3>
                    </div>
                  </div>
                </div>
                <div class="face face2">
                  <div class="content">
                    <p>
                      Dilma Capital Holdings(Pvt)Ltd, No : 546/2, Mihindu
                      Mawatha, Athurugiriya Road, Malabe
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div className="face face1">
                  <div className="top_box">
                    <div class="content">
                      <img src="./images/mobile_2.png" />
                      <h3>MOBILE</h3>
                    </div>
                  </div>
                </div>
                <div class="face face2">
                  <div class="content">
                    <p>
                      +94 112 744 044<br></br>
                      +94 716 735 100
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="face face1">
                  <div className="top_box">
                    <div class="content">
                      <img src="./images/email_2.png" />
                      <h3>EMAIL</h3>
                    </div>
                  </div>
                </div>
                <div class="face face2">
                  <div class="content">
                    <p>info@dch.lk</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="contact_img" src="./images/contact_cov2.jpg"></img>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
