import SampleImage from "../images/raw_oil.jpg";

export const Gallery_list = [
  {
    id: "1",
    imageName: "../images/opening/1.jpg",
    tag: "event",
  },
  {
    id: "2",
    tag: "event",
    imageName: "../images/opening/15.jpg",
  },
  {
    id: "3",
    tag: "event",
    imageName:"../images/opening/17.jpg",
  },
  {
    id: "4",
    tag: "event",
    imageName:"../images/opening/25.jpg",
  },
  {
    id: "5",
    tag: "event",
    imageName:"../images/opening/3.jpg",
  },
  {
    id: "6",
    tag: "event",
    imageName:"../images/meetingjan1st/1.jpg",
  },
  {
    id: "7",
    tag: "event",
    imageName:"../images/meetingjan1st/3.jpg",
  },
  {
    id: "7",
    tag: "event",
    imageName:"../images/meetingjan1st/8.jpg",
  },
  {
    id: "8",
    tag: "event",
    imageName:"../images/meetingjan1st/9.jpg",
  },
  {
    id: "8",
    tag: "event",
    imageName:"../images/meetingjan1st/10.jpg",
  },
  {
    id: "9",
    tag: "event",
    imageName:"../images/pirith/3.jpg",
  },
  {
    id: "10", 
    tag: "event",
    imageName:"../images/pirith/6.jpg",
  },
  {
    id: "11",
    tag: "event",
    imageName:"../images/pirith/7.jpg",
  },
  {
    id: "12",
    tag: "event",
    imageName:"../images/pirith/9.jpg",
  },



];
