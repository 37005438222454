import Header from "../Header/Header";

import Footer from "../Footer/Footer.js";
import "./AboutUs.css";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";


const AboutUs = () => {
  return (
    <div>
      <Header />




      <div className="about_us_body">
       
     
 
        {/* LEADERS OF COMPANY
        
        <p className="team_title">OUR TEAM</p>

        <div className="team_of_company">

          <div className="teambox">
            <img className="circle" src="../images/direct.jpg"></img>

            <p className="team_posts">Lasith Dias</p>
            <p className="team_msgs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
            <p className="team_position">Creative Leader</p>
          </div>
          <div className="teambox">
            <img className="circle" src="../images/direct.jpg"></img>

            <p className="team_posts">Madhavi Dias</p>
            <p className="team_msgs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
            <p className="team_position">Directress</p>
          </div>

          <div className="teambox">
            <img className="circle" src="../images/direct.jpg"></img>

            <p className="team_posts">Sumudu Kiriella</p>
            <p className="team_msgs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
            <p className="team_position">Sales Manager</p>
          </div>
          <div className="teambox">
            <img className="circle" src="../images/direct.jpg"></img>

            <p className="team_posts"> Anushi Jayarathne</p>
            <p className="team_msgs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
            <p className="team_position">Doctor</p>
          </div>
          <div className="teambox">
            <img className="circle" src="../images/direct.jpg"></img>

            <p className="team_posts">Lasith Dias</p>
            <p className="team_msgs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
            <p className="team_position">Creative Leader</p>
          </div>
        </div> */}

        <div className="goc">
          <p className="gofcom">GROUP OF COMPANIES</p>
        </div>
      </div>

      <div class="cards">
        <a href="https://dilmagroupcolombo.lk/dilma-auto-trading-limited">
          <div class="card_squre">
            <h2 class="card-title1">DAT</h2>
            <img src="../images/dat.jpg" alt="" />
            <p class="card-desc1">
              Dilma Auto Trading has been in the forefront for many years
              providing quality spare parts for motor bikes and Three-Wheel
              sector adhering to the highest standards at all times.
            </p>
          </div>
        </a>
        <a href="https://dilmagroupcolombo.lk/dilma-poly-preen-engineering">
          <div class="card_squre">
            <h2 class="card-title2">DPPE</h2>
            <img src="../images/dppe.jpg" alt="" />
            <p class="card-desc2">
              Dilma Poly Preen Engineering manufacture quality auto motive
              rubber parts and it proudly present high quality oil seals for
              automotive as well as industrial requirements.
            </p>
          </div>
        </a>

        <a href="https://dilmagroupcolombo.lk/dilma-capital-holdings">
          <div class="card_squre">
            <h2 class="card-title3">DCH</h2>
            <img src="../images/dch.jpg" alt="" />
            <p class="card-desc3">
              Dilma Capital Holdings is a business focused at promoting and
              encouraging healthy foods,toxic free agriculture and native
              Ayurveda traditions that are essential for a quality living.
            </p>
          </div>
        </a>
        <a href="https://dilmagroupcolombo.lk/petroleum-system-international">
          <div class="card_squre">
            <h2 class="card-title4">PSI</h2>
            <img src="../images/petrol.jpg" alt="" />
            <p class="card-desc4">
              Petroleum System International is a leading break oil supplier for
              three wheeler and Motor Cycle market with its SPEED Dot 3 Break
              Oil.
            </p>
          </div>
        </a>
        <a href="https://dilmagroupcolombo.lk/desinghe-pathirage-motor-limited">
          <div class="card_squre">
            <h2 class="card-title5">DPMC</h2>
            <img src="../images/dpmc.jpg" alt="" />
            <p class="card-desc5">
              As a manufacturer of local steel auto parts, the company offers
              number of products relating to three wheelers and Motor cycles.
            </p>
          </div>
        </a>
        <a href="https://dilmagroupcolombo.lk/dat-management-services-limited">
          <div class="card_squre">
            <h2 class="card-title6">DAT MANAGEMENT</h2>
            <img src="../images/datms.jpg" alt="" />
            <p class="card-desc6">
              Dat Management Services is dedicated in providing education
              service beyond the traditional frame to enhance the imagination
              and creativity of younger generation of the country.
            </p>
          </div>
        </a>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
