// import "./Header.css";
import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Header_Responsive.css";
import "../NavBar/Navbar";
import { Link } from "react-router-dom";
import BrandLogo from "../images/Desinghage-Logo.png";
import brandName from "../images/brand_name.png";
import SlideImg1 from "../images/slide3.jpg";

const slideImages = [
  "../images/slide3.jpg",
  "../images/1.jpg",
  "../images/2.jpg",
  "../images/ayur.png",
];

const Header = () => {
  return (
    <html>
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
      </head>

      <div className="header">
        <div className="top_main"></div>
        <div className="slide_show">
          <div>
            <Slide easing="ease">
              <div className="each-slide">
                <div
                  style={{ backgroundImage: `url(${slideImages[0]})` }}
                ></div>
              </div>
              <div className="each-slide">
                <div
                  style={{ backgroundImage: `url(${slideImages[1]})` }}
                ></div>
              </div>
              <div className="each-slide">
                <div
                  style={{ backgroundImage: `url(${slideImages[2]})` }}
                ></div>
              </div>
              <div className="each-slide">
                <div
                  style={{ backgroundImage: `url(${slideImages[3]})` }}
                ></div>
              </div>
            </Slide>
          </div>
        </div>
        <div className="top_main_left_side mb-4">
          <img className="left_chakra" src="../images/chakra_left.png"></img>
        </div>

        <div className="top_main_right_side">
          <img className="right_chakra" src="../images/chakra_right.png"></img>
        </div>

        <div className="top_main_name">
          <img className="branding_name" src={brandName} />
        </div>
        <img className="brand_logo" src={BrandLogo} />
        <input type="checkbox" id="check"></input>
        <label for="check" class="checkbtn">
          <i class="fa fa-bars"></i>
        </label>

        <div className="top_main_navebar">
          <div className="navebar_lesftside">
            <Link to="/" className="link_to">
              {" "}
              <div className="btn_home">
                <p className="home_btn">HOME</p>
              </div>
            </Link>
            <Link to="/about" className="link_to">
              <div className="btn_Company">
                <p className="company_btn">ABOUT US</p>
              </div>
            </Link>
            <Link to="/product" className="link_to">
              <div className="btn_products">
                <p className="products_btn">PRODUCTS</p>
                <div className="dropdown_products">
                  <Link to="/Product_list_without_image/oil" className="link">
                    AYURVEDIC OIL
                  </Link>
                  <Link to="/Product_list_without_image/guli" className="link">
                    AYURVEDIC GULI
                  </Link>
                  <Link to="/Product_list_without_image/ghrita" className="link">
                    AYURVEDIC GHRITA
                  </Link>
                  <Link to="/Product_list_without_image/unanisiddi" className="link">
                    UNANI &amp; SIDDI
                  </Link>
                  <Link to="/Product_list_without_image/pattu" className="link">
                    PATTU
                  </Link>
                  <Link to="/Product_list_without_image/rasa" className="link">
                    RASAYANA
                  </Link>
                  <Link to="/Product_list_without_image/kalka" className="link">
                    KALKA
                  </Link>
                  <Link to="/Product_list_without_image/arishta" className="link">
                    ARISHTA
                  </Link>
                  <Link to="/Product_list_without_image/tablets" className="link">
                    TABLETS
                  </Link>
                  <Link to="/Product_list_without_image/rawoil" className="link">
                    RAW OIL
                  </Link>
                </div>
              </div>
            </Link>
          </div>

          <div className="navebar_middleside">
            <div className="brand_name_english">
              <p className="brandengname">DESINGHAGE OSU </p>
            </div>
          </div>

          <div className="navebar_right_side">
            <Link to="/ContactUs" className="link_to">
              {" "}
              <div className="btn_contacts">
                <p className="contact_btn">CONTACT US</p>
              </div>
            </Link>
            <Link to="/Careers" className="link_to">
              <div className="btn_careers">
                {" "}
                <p className="careers_btn">CAREERS</p>
              </div>
            </Link>
            <Link to="/Gallery" className="link_to">
              <div className="btn_careers">
                {" "}
                <p className="careers_btn"> GALLERY</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </html>
  );
};
export default Header;
