import Header from "../Header/Header";
import "./Home.css";
import Footer from "../Footer/Footer.js";
import { Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { useMediaQuery } from "react-responsive";

function Home() {
  const isMobileDevice = useMediaQuery({
    query: "(min-device-width: 480px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-device-width: 1201px )",
  });

  return (
    <html>
      <head></head>
      <body>
        <div className="Home">
          <Header />

          <p className="topic_welcome">
            <center>WELCOME TO DESINGHAGE OSU</center>
          </p>
          <div className="welcome_message">
            <p className="company_welcome_pro">
              The Dilma Group is one of the few private organizations working to
              develop Sri Lankan indigenousness. For that, the local herbal
              brand name Desinghage Osu will be introduced to the general public
              as a strong economic plan to nurture the Sri Lankan Hela Tradition
              and Create a healthy Society with the aim of producing medicines
              and food within the essential consumer segment.
            </p>
          </div>
          {/* 
          <div className="body_products_btns">
            <img
              className="product_button_image"
              src="./images/product_btn_wallpaper.jpg"
            ></img>
            <div className="button_body">
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
              <button type="button" class="btn_pro">
                Ayurvedic Oil
              </button>
            </div>
          </div>
*/}
          <div className="latest_event ">
            <p className="latest_event_name">LATEST EVENTS</p>
          </div>

          <div className="l_event">
            <marquee behavior="alternate" scrolldelay="400">
              <div className="slider">
                <div className="slider_track">
                  <div className="slides">
                    <img
                      src="./images/opening1.png"
                      width="300px"
                      height="200px"
                      alt=""
                      class="img"
                      id="img1"
                    ></img>
                  </div>

                  <div className="slides">
                    <img
                      src="./images/meeting.png"
                      width="300px"
                      height="200px"
                      alt=""
                      class="img"
                      id="img1"
                    ></img>
                  </div>
                  <div className="slides">
                    <img
                      src="./images/pirith.png"
                      width="300px"
                      height="200px"
                      alt=""
                      class="img"
                      id="img1"
                    ></img>
                  </div>
                </div>
              </div>
            </marquee>
          </div>

          <div></div>
          <Footer />
        </div>
      </body>
    </html>
  );
}

export default Home;
