import Header from "../Header/Header";
import Footer from "../Footer/Footer.js";
import "./Gallery.css";
import { Gallery_list } from "./Gallery_list";
import React, { useEffect, useState } from "react";
import { Modal } from "antd";

function Gallery() {
  const [tag, setTag] = useState("all");
  const [filteredImages, setFilteredImages] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectImage, setSelectImage] = useState("");

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(Gallery_list)
      : setFilteredImages(Gallery_list.filter((image) => image.tag === tag));
  }, [tag]);
  return (
    <div>
      <div>
        <Header />
      </div>
      <div className="image_topics" handleSetTag={setTag}>
        <TagButton
          name="all"
          handleSetTag={setTag}
          tagActive={tag === `all` ? true : false}
        />
        <TagButton
          name="industrial"
          handleSetTag={setTag}
          tagActive={tag === `industrial` ? true : false}
        />
        <TagButton
          name="Social"
          handleSetTag={setTag}
          tagActive={tag === `Social` ? true : false}
        />
        <TagButton
          name="event"
          handleSetTag={setTag}
          tagActive={tag === `event` ? true : false}
        />
      </div>
    <div className="Gallery_area">
      <div className="Gallery_body">
        {filteredImages.map((image) => (
          <div
            className="image"
            key={image.id}
            onClick={() => {
              toggleModal();
              setSelectImage(`../images/${image.imageName}`);
            }}
          >
            <img
              className="images_gallery"
              src={`../images/${image.imageName}`}
            />
          </div>
        ))}
      </div>
      </div>
      <Modal
        // title="Basic Modal"
        visible={isModalVisible}
        // onOk={handleOk}
        // onCancel={toggleModal}
        onCancel={toggleModal}
        closable={true}
        footer={false}
        closeIcon={<div className="closeIcon">X</div>}
      >
        <img className="modalImage" src={selectImage} />
      </Modal>

      <div>
        <Footer />
      </div>
    </div>
  );
}
const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className={`tag ${tagActive ? `active` : null}`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()} {``}
    </button>
  );
};

export default Gallery;
