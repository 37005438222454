import Header from "../Header/Header";
import Footer from "../Footer/Footer.js";
import "./Careers.css";

function Careers(){

 
  return (
    <div>
      <div>
        <Header />
      </div>
    {/*  <div className="topic">
        <p className="topic_para">CAREER OPPORTUNITIES</p>
      </div>

      <div className="career_page_body">
        <div className="left_side">
          <img className="career_img" src="../images/career.jpg"></img>
        </div>
        <div className="right_side">
          <form className="contact_info">
            <div class="row mb-4">
              <div class="col">
                <div class="form-outline">
                  <input type="text" id="form6Example1" class="form-control" />
                  <label class="form-label" for="form6Example1">
                    First name
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="form-outline">
                  <input type="text" id="form6Example2" class="form-control" />
                  <label class="form-label" for="form6Example2">
                    Last name
                  </label>
                </div>
              </div>
            </div>

            <div class="form-outline mb-4">
              <input type="text" id="form6Example4" class="form-control" />
              <label class="form-label" for="form6Example4">
                Address
              </label>
            </div>

            <div class="form-outline mb-4">
              <input type="email" id="form6Example5" class="form-control" />
              <label class="form-label" for="form6Example5">
                Email
              </label>
            </div>

            <div class="form-outline mb-4">
              <input type="number" id="form6Example6" class="form-control" />
              <label class="form-label" for="form6Example6">
                Phone
              </label>
            </div>

            <label for="myfile" className="myfile">
              Upload your CV here :
            </label>
            <div class="file-upload-wrapper">
              <input
                type="file"
                id="input-file-now-custom-2"
                class="file-upload"
              />
            </div>

            <div class="form-outline mb-4">
              <textarea
                class="form-control"
                id="form6Example7"
                rows="4"
              ></textarea>
              <label class="form-label" for="form6Example7">
                Additional information
              </label>
            </div>

            <button type="submit" class="btn btn-primary btn-block mb-4">
              Submit
            </button>
          </form>
        </div>
      </div>
 */}

<a
             
              href="mailto:dilma@dch.lk"
            >

 <div className="careers_body">
   <div className="c_left">
     <img className="left_img" src="./images/office.png"></img>
   </div>
   
 </div> </a>

      <div >
        <Footer />
      </div>
    </div>
  );
};

export default Careers;
