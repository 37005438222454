import { useParams } from "react-router";
import { products } from "../db/ProductListDB";
import "./ProductCategory.css";

const ProductCategory = ({ withoutImage }) => {
  const param = useParams();
  const { category } = param;

  return (
    <div className="ProductList">
      {products
        .filter((product) =>
          category === "all" ? true : product.category === category
          
        )
        .map((product) => (
          <div className="Product">
            <div className="pro_name"> {product.name}</div>
            <div className="pro_img">
              {!withoutImage && <img src={product.image} />}
            </div>
            {/*     <div className="pro_quntity">
              <div className="quan_data">
                <p className="quan_name">30ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">100ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">150ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">200ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">375ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">500ml</p>
              </div>
              <div className="quan_data">
                <p className="quan_name">750ml</p>
              </div>
        </div> 
            </div>
        </div> 

            <div className="pro_price">RS {product.price}</div>*/}
          </div>
        ))}
    </div>
  );
};

export default ProductCategory;
