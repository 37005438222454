import SampleImage from "../images/raw_oil.jpg";

export const products = [
  {
    id: "1",
    name: "ASHWAGANDA THAILAYA",
    category: "oil",
    image: "",
    price: "500.00",
  },
  {
    id: "2",
    name: "SHULAHARA THAILAYA",
    category: "oil",
    image: "",
    price: "500.00",
  },
  {
    id: "3",
    name: "KUBJA PRASARANI THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "4",
    name: "BALAKORANDA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "5",
    name: "SARVAVISHADI THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "6",
    name: "NARAYANA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "7",
    name: "MAHA NARAYANA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "8",
    name: "BATU THAILAYA",
    category: "oil",
    image: "",

  },
  {
    id: "9",
    name: "SEETHODAKA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "10",
    name: "SIDDARTHA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "11",
    name: "BHRUNGAMALAKA THAILAYA",
    category: "oil",
    image: "",
  },
  {
    id: "12",
    name: "IRIMEDADI THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "13",
    name: "NEELYADI THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "14",
    name: "KAPURU THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "15",
    name: "PAKSHAGHATA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "16",
    name: "PINDA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "17",
    name: "KADUM BINDUM THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "18",
    name: "KOLASHLESHMA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "19",
    name: "VATA VIDURANGA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "20",
    name: "SARPASHADI THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "21",
    name: "RATHA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "22",
    name: "KASISADI THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "23",
    name: "MAHAMASHA THAILAYA",
    image: "",
    category: "oil"
  },
  {
    id: "24",
    name: "ASHWAGANDA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "25",
    name: "DHATRI CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "26",
    name: "SUKUMARA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "27",
    name: "AVIPATTIKARA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "28",
    name: "MANIBHADRA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "29",
    name: "SEETHOPALADI CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "30",
    name: "BHASHKARA LAVANA",
    image: "",
    category: "churna"
  },
  {
    id: "31",
    name: "PUSHYANUGA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "32",
    name: "SUDARSHANA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "33",
    name: "TRIPALA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "34",
    name: "DASHANGALEPAYA CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "35",
    name: "THALISADI CHURNAYA",
    image: "",
    category: "churna"
  },
  {
    id: "36",
    name: "YOGARAJA GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "37",
    name: "KAISHORA",
    image: "",
    category: "guli"
  },
  {
    id: "38",
    name: "MADHUMEHA VATI",
    image: "",
    category: "guli"
  },
  {
    id: "39",
    name: "CHANDRAPRABHA VATI",
    image: "",
    category: "guli"
  },
  {
    id: "40",
    name: "PANCHATIKTA GHRITA GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "41",
    name: "KANCHANARA GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "42",
    name: "GOKSHURADI GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "43",
    name: "SINHANDA GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "44",
    name: "PUNARNAVADI GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "45",
    name: "RASNADI GUGGULU",
    image: "",
    category: "guli"
  },
  {
    id: "46",
    name: "ASHWAGANDHA RASAYANA",
    image: "",
    category: "rasayana"
  },
  {
    id: "47",
    name: "KUSHMANDA RASAYANA",
    image: "",
    category: "rasayana"
  },
  {
    id: "48",
    name: "RANAHANSA RASAYANA",
    image: "",
    category: "rasayana"
  },
  {
    id: "49",
    name: "DHATUPRUSHTI RASAYANA",
    image: "",
    category: "rasayana"
  },
  {
    id: "50",
    name: "NAVARATHNNA KALKAYA",
    image: "",
    category: "kalka"
  },
  {
    id: "51",
    name: "CHANDRA KALKAYA",
    image: "",
    category: "kalka"
  },
  {
    id: "52",
    name: "RATHA KALKAYA",
    image: "",
    category: "kalka"
  },
  {
    id: "53",
    image: "",
    name: "SHARKARADI KALKAYA",
    category: "kalka"
  },
  {
    id: "54",
    name: "BUDDHARAJA KALKAYA",
    image: "",
    category: "kalka"

  },
  {
    id: "55",
    name: "DASAMULARISHTAYA",
    image: "",
    category: "arishta"
  },
  {
    id: "56",
    name: "BALARISHTAYA",
    image: "",
    category: "arishta"
  },
  {
    id: "57",
    name: "ASHOKARISHTAYA",
    image: "",
    category: "arishta"
  },
  {
    id: "58",
    name: "ASHVAGANDHARISHTAYA",
    image: "",
    category: "arishta"
  },
  {
    id: "59",
    name: "AGNIMULARISHTAYA",
    image: "",
    category: "arishta"
  },
  {
    id: "60",
    name: "ASHVAGANDA GHRITA",
    image: "",
    category: "ghrita"
  },
  {
    id: "61",
    name: "BHRAHMI GHRITA",
    image: "",
    category: "ghrita"
  },
  {
    id: "62",
    name: "CHAGALADI GHRITA",
    image: "",
    category: "ghrita"
  },
  {
    id: "63",
    name: "LAKSHADI LEPAYA",
    image: "",
    category: "pattu"
  },
  {
    id: "64",
    name: "KETAKELA PATHTHUWA",
    image: "",
    category: "pattu"
  },
  {
    id: "65",
    name: "DIYALABU PATHTHUWA",
    image: "",
    category: "pattu"
  },
  {
    id: "66",
    name: "MANJANE SRUK",
    image: "",
    category: "unanisiddi"
  },
  {
    id: "67",
    name: "SAPUPE SURINJAN",
    image: "",
    category: "unanisiddi"
  },
  {
    id: "68",
    name: "ROHAN - E - KHAS",
    image: "",
    category: "unanisiddi"
  },
  {
    id: "69",
    name: "SHARBAT - E - BAZOORI",
    image: "",
    category: "unanisiddi"
  },
  {
    id: "70",
    name: "SHARBAT - E - SAUL",
    image: "",
    category: "unanisiddi"
  },
  {
    id: "71",
    name: "SEETARAMA VATI",
    image: "",
    category: "rasa"
  },
  {
    id: "72",
    name: "SHANKHA VATI",
    image: "",
    category: "rasa"
  },
  {
    id: "73",
    name: "VATAGAJENDRASINGHA RASA",
    image: "",
    category: "rasa"
  },
  {
    id: "74",
    name: "SHVASAKUTARA RASA",
    image: "",
    category: "rasa"
  },
  {
    id: "75",
    name: "SURANVIDURA VIREKA PANGUWA",
    image: "",
    category: "rasa"
  },
  {
    id: "76",
    name: "SURANVIDURA PATHYA PANGUWA",
    image: "",
    category: "rasa"
  },
  {
    id: "77",
    name: "MUSTARD OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "78",
    name: "CASTOR OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "79",
    name: "SESAME OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "80",
    name: "GHEE OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "81",
    name: "NEEM OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "82",
    name: "ROSE WATER",
    image: "",
    category: "rawoil"
  },
  {
    id: "83",
    name: "BEE HONEY",
    image: "",
    category: "rawoil"
  },
  {
    id: "84",
    name: "MEE OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "85",
    name: "PAS THEL",
    image: "",
    category: "rawoil"
  },
  {
    id: "86",
    name: "CITRONELLLA OIL",
    image: "",
    category: "rawoil"
  },
  {
    id: "87",
    name: "THRIPALA TABLETS",
    image: "",
    category: "tablets"
  },
  {
    id: "88",
    name: "SUDARSHANA TABLETS",
    image: "",
    category: "tablets"
  },
  {
    id: "89",
    name: "EKAVERIYA/SARPAGANDA VATI",
    image: "",
    category: "tablets"
  },
  {
    id: "90",
    name: "SUDUHANDUN",
    category: "churna"
  },
  {
    id: "91",
    name: "RATHHANDUN",
    image: "",
    category: "churna"
  },
  {
    id: "92",
    name: "ARALU",
    image: "",
    category: "churna"
  },
  {
    id: "93",
    name: "BULU",
    image: "",
    category: "churna"
  },
  {
    id: "94",
    name: "NELLI",
    image: "",
    category: "churna"
  },
  {
    id: "95",
    name: "WELMI",
    image: "",
    category: "churna"
  },
  {
    id: "96",
    name: "RASAKINDA",
    image: "",
    category: "churna"
  },
  {
    id: "97",
    name: "VENIVAL",
    image: "",
    category: "churna"
  },
  {
    id: "98",
    name: "THRIPALA",
    image: "",
    category: "oil"
  },
  {
    id: "99 ",
    name: "SATHTHAWADI",
    image: "",
    category: "oil"
  },
];
