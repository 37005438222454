import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div className="bottom_of_page">
        <div className="b_page_links">
          <Link to="/" className="link_to">
            <div className="b_page_link">
              {" "}
              <p className="pages_link">Home</p>
            </div>
          </Link>
          <Link to="/about" className="link_to">
            <div className="b_page_link">
              <p className="pages_link">About Us</p>
            </div>
          </Link>
          <Link to="/product" className="link_to">
            <div className="b_page_link">
              <p className="pages_link">Products</p>
            </div>
          </Link>

          <Link to="/ContactUs" className="link_to">
            <div className="b_page_link">
              <p className="pages_link">Contact Us</p>
            </div>
          </Link>
          <Link to="/Careers" className="link_to">
            <div className="b_page_link">
              <p className="pages_link">Careers</p>
            </div>
          </Link>
          <Link to="/Gallery" className="link_to">
            <div className="b_page_link">
              <p className="pages_link">Gallery</p>
            </div>
          </Link>
        </div>
        <div className="b_left">
          <p className="company_mobile">
            <a className="contact_topics"> Contacts </a>
            <br></br>
            <a> +94 112 744 044</a>
            <br></br>
            <a> +94 716 735 100</a>
            <br></br>
          </p>
        </div>
        <div className="b_middle">
          <div className="sl_firstrow">
            <div className="social_m_link">
              <a
                className="facebook"
                href="https://www.facebook.com/DesinghageOsu"
              >
                {" "}
                <div className="facebook">
                  <i class="fa fa-facebook fa-3x facebook-custom"></i>
                </div>
              </a>

              <a className="whatsapp" href="">
                {" "}
                <div className="whatsapp">
                  <i class="fa fa-whatsapp fa-3x whatsapp-custom"></i>
                </div>
              </a>

              <div className="youtube">
                <i class="fa fa-youtube fa-3x youtube-custom"></i>
              </div>
            </div>
          </div>
          <div className="sl_secrow">
            <a
              className="company_email"
              href="mailto:desinghage.mgrmedicine@dch.lk"
            >
              <p className="company_email">dilma@dch.lk</p>
            </a>
          </div>
        </div>

        <div className="b_right">
          <div className="company_address">
            <a className="contact_topics"> Address</a>
            <br></br>
            Dilma Capital Holdings (Pvt) Ltd,<br></br>
            No : 546/2,<br></br>
            Mihindu Mawatha,<br></br>
            Athurugiriya Road,<br></br>
            Malabe, <br></br>
            Sri Lanka.
          </div>
        </div>
      </div>
      <footer>
        <p className="footer_title">&copy;2022. Dilma Group Colombo.</p>
      </footer>
    </div>
  );
};
export default Footer;
